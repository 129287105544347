import {ExperimentNames, getFormattedLocation} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import sc from '../classes.scss'
import s from './short-date-location.scss'

interface ShortDateLocationProps {
  event: wix.events.Event
  dateClassName: string
  locationClassName: string
}

export const ShortDateLocation = ({event, dateClassName, locationClassName}: ShortDateLocationProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  const {
    allBreakpoints: {isListVenueNameEnabled, isListDateEnabled, isListDateVisible_V2, isListLocationVisible_V2},
  } = useVisibilityStyles()
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  const [venueVisible, dateVisible] = listDateLocationFormatSettingEnabled
    ? [isListLocationVisible_V2(), isListDateVisible_V2()]
    : [isListVenueNameEnabled(), isListDateEnabled()]

  return (
    <div
      className={classNames(s.container, sc.textLineHeight, s.alignment, {
        [s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled,
      })}
      data-hook="ev-short-date-location"
    >
      {dateVisible ? (
        <div className={classNames(s.date, dateClassName)} data-hook="short-date">
          {shortStartDate}
        </div>
      ) : null}
      <div className={classNames(s.divider, dateClassName)} />
      {venueVisible ? (
        <div className={classNames(s.location, locationClassName)} data-hook="short-location">
          {getFormattedLocation(event)}
        </div>
      ) : null}
    </div>
  )
}
