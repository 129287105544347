import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  selectCanApproveMembers,
  selectGroup,
  selectMembersWithCountLabel,
} from 'store/selectors';

import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import { Box } from 'wui/Box';
import { Stack } from 'wui/Stack';
import { Typography, ITypographyProps } from 'wui/Typography';
import { GroupMembershipButton } from 'common/components/GroupMembership';

import { GroupPrivacyIcon } from '../GroupPrivacyIcon';
import { GroupPrivacyLabel } from '../GroupPrivacyLabel';
import { PendingMembersCount } from './PendingMembersCount';
import { MemberCountLabel } from './MemberCountLabel';

import classes from './GroupInfo.scss';

interface IGroupInfoProps
  extends Omit<React.ComponentProps<typeof Stack>, 'children'> {
  wired?: boolean;
  groupId: string;
  misc?: boolean;
  variant?: ITypographyProps['variant'];
  extended?: boolean; // for {GROUP-8245} - update sidebar layout
}

export function GroupInfo({
  groupId,
  misc,
  wired,
  variant,
  extended = true,
  'data-hook': dataHook,
  ...rest
}: IGroupInfoProps) {
  const { isMobile } = useEnvironment();

  const group = useSelector(selectGroup(groupId));
  const memberLabel = useSelector(selectMembersWithCountLabel(groupId));
  const canApproveMembers = useSelector(selectCanApproveMembers(groupId));

  const pendingMembersCount = group.pendingMembersCount;

  if (pendingMembersCount && canApproveMembers && misc) {
    return (
      <PendingMembersCount
        count={pendingMembersCount}
        dataHook={dataHook}
        params={{ slug: group.slug, expandJoinedRequests: true }}
        withSeparator={false}
      />
    );
  }

  if (!extended && !isMobile) {
    return (
      <Box direction="vertical" gap="SP0">
        <Typography
          as="div"
          variant={variant}
          secondary={!wired}
          data-hook={dataHook}
          className={cls(classes.root, {
            [classes.wired]: wired,
          })}
        >
          <Stack verticalAlign="middle" {...rest}>
            <GroupPrivacyIcon
              group={group}
              size={20}
              data-hook="group-privacy"
              fillIcon
              showTooltip
            />{' '}
            <MemberCountLabel
              memberLabel={memberLabel}
              membersCount={group.membersCount ?? 0}
              dataHook="members-count"
            />
          </Stack>
        </Typography>
        <Box>
          <GroupMembershipButton
            redirect
            className={classes.action}
            variant="basic"
            outlined
            groupId={group.id as string}
            bi={groupsRequestJoinAGroup({
              group_id: group.id as string,
              origin: 'new_layout_groups_sidebar_suggested_groups',
            })}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Typography
      as="div"
      variant={variant}
      secondary={!wired}
      data-hook={dataHook}
      className={cls(classes.root, {
        [classes.wired]: wired,
        [classes.mobile]: isMobile,
      })}
    >
      <Stack truncate {...rest}>
        <GroupPrivacyLabel
          group={group}
          data-hook="group-privacy"
          className={classes.privacyLabel}
        />
        <MemberCountLabel
          memberLabel={memberLabel}
          membersCount={group.membersCount ?? 0}
          withSeparator
          dataHook="members-count"
        />
      </Stack>
    </Typography>
  );
}

GroupInfo.displayName = 'GroupInfo';
