import { AppThunk, AllActions } from '../../store-types';
import { ErrorLoud, PaginationConfig } from '../../../common-types';
import { uniqueId } from 'lodash';
import { assertDefined, isOfType } from '~/ts-utils';
import { getGenericError } from '../reviews-errors';
import {
  FetchReviewsResponse,
  FetchReviewsResponseFull,
  Ordering,
} from '../../../../controller/lib/reviews-api-types';
import { orderingAndFilterMachine } from '~reviews/common/services/ordering-and-filter-utils';

export const FETCH_REVIEWS_INITIAL_REQUEST = 'FETCH_REVIEWS_INITIAL_REQUEST' as const;
export const FETCH_REVIEWS_INITIAL_SUCCESS = 'FETCH_REVIEWS_INITIAL_SUCCESS' as const;
export const FETCH_REVIEWS_INITIAL_FAILURE = 'FETCH_REVIEWS_INITIAL_FAILURE' as const;

export const fetchReviewsInitialRequest = (payload: {
  resourceId: string;
  requestId: string;
  pagination: PaginationConfig;
  namespace: string;
}) => ({
  type: FETCH_REVIEWS_INITIAL_REQUEST,
  payload,
});

export const fetchReviewsInitialSuccess = (payload: {
  response: FetchReviewsResponseFull;
  requestId: string;
}) => ({
  type: FETCH_REVIEWS_INITIAL_SUCCESS,
  payload,
});

export const fetchReviewsInitialFailure = (payload: { resourceId: string; requestId: string }) => ({
  type: FETCH_REVIEWS_INITIAL_FAILURE,
  payload,
});

const isUUID = (str: string): boolean => {
  const uuidV4Regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidV4Regex.test(str);
};

export function fetchReviewsInitial({
  resourceId,
  pagination,
  namespace,
}: {
  resourceId: string;
  pagination: PaginationConfig;
  namespace: string;
}): AppThunk {
  return async (dispatch, getState, { wixReviewsApi, flowApi }) => {
    const reviewsState = getState().reviews;
    const requestId = uniqueId();
    // We don't really expect widgets to be installed standalone currently.
    if (namespace !== 'stores' || !isUUID(resourceId)) {
      flowApi.errorMonitor.captureException(new Error('Widget appears to be standalone'), {
        tags: { resourceId, namespace },
      });
    }
    if (
      reviewsState.type === 'PENDING' &&
      reviewsState.config.namespace === namespace &&
      reviewsState.config.resourceId === resourceId
    ) {
      return;
    }

    dispatch(
      fetchReviewsInitialRequest({
        resourceId,
        requestId,
        pagination,
        namespace,
      }),
    );

    return wixReviewsApi
      .fetchReviewsInitial({ resourceId, pagination, namespace })
      .then((response) => {
        dispatch(fetchReviewsInitialSuccess({ response, requestId }));
      })
      .catch((e) => {
        console.error(e);
        dispatch(fetchReviewsInitialFailure({ resourceId, requestId }));
      });
  };
}

// repeat inital fetch
export function refetchReviewsInitial(): AppThunk {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type === 'INITIAL') {
      return;
    }
    dispatch(fetchReviewsInitial(reviewsState.config));
  };
}

export const FETCH_DEEP_LINK_REQUEST = 'FETCH_DEEPLINK_REQUEST' as const;
export const FETCH_DEEP_LINK_SUCCESS = 'FETCH_DEEPLINK_SUCCESS' as const;
export const FETCH_DEEP_LINK_FAILURE = 'FETCH_DEEPLINK_FAILURE' as const;

export const fetchDeepLinkRequest = (payload: {
  resourceId: string;
  requestId: string;
  pagination: PaginationConfig;
  reviewId: string;
  namespace: string;
}) => ({
  type: FETCH_DEEP_LINK_REQUEST,
  payload,
});

export const fetchDeepLinkSuccess = (payload: {
  response: FetchReviewsResponseFull;
  requestId: string;
}) => ({
  type: FETCH_DEEP_LINK_SUCCESS,
  payload,
});

export const fetchDeepLinkFailure = (payload: { resourceId: string; requestId: string }) => ({
  type: FETCH_DEEP_LINK_FAILURE,
  payload,
});

export function fetchDeepLink({
  resourceId,
  pagination,
  reviewId,
  namespace,
}: {
  resourceId: string;
  pagination: PaginationConfig;
  reviewId: string;
  namespace: string;
}): AppThunk {
  return (dispatch, _getState, { wixReviewsApi }) => {
    const requestId = uniqueId();
    dispatch(
      fetchDeepLinkRequest({
        resourceId,
        requestId,
        pagination,
        reviewId,
        namespace,
      }),
    );

    return wixReviewsApi
      .fetchDeepLink({ resourceId, reviewId, namespace })
      .then((response) => dispatch(fetchDeepLinkSuccess({ response, requestId })))
      .catch(() => dispatch(fetchDeepLinkFailure({ resourceId, requestId })));
  };
}

export const FETCH_REVIEWS_PAGE_REQUEST = 'FETCH_REVIEWS_PAGE_REQUEST' as const;
export const FETCH_REVIEWS_PAGE_SUCCESS = 'FETCH_REVIEWS_PAGE_SUCCESS' as const;
export const FETCH_REVIEWS_PAGE_FAILURE = 'FETCH_REVIEWS_PAGE_FAILURE' as const;

export const fetchReviewsPageRequest = (payload: { requestId: string }) => ({
  type: FETCH_REVIEWS_PAGE_REQUEST,
  payload,
});

export const fetchReviewsPageSuccess = (payload: {
  response: FetchReviewsResponse;
  requestId: string;
  action: 'prev' | 'next';
}) => ({
  type: FETCH_REVIEWS_PAGE_SUCCESS,
  payload,
});

export const fetchReviewsPageFailure = (payload: { requestId: string; error: ErrorLoud }) => ({
  type: FETCH_REVIEWS_PAGE_FAILURE,
  payload,
});

export function fetchNextReviews(): AppThunk {
  return (dispatch, getState, { wixReviewsApi }) => {
    const state = getState().reviews;
    if (state?.type !== 'READY') {
      return;
    }

    const cursor =
      state.reviewList.type === 'READY' ? state.reviewList.chunk.nextCursor : undefined;

    assertDefined(cursor);
    const { pagination } = state.config;

    const requestId = uniqueId();
    dispatch(fetchReviewsPageRequest({ requestId }));

    return wixReviewsApi
      .fetchReviewsChunk({
        ...state.config,
        pagination,
        cursor,
      })
      .then((response) =>
        dispatch(fetchReviewsPageSuccess({ response, requestId, action: 'next' })),
      )
      .catch((e) =>
        dispatch(fetchReviewsPageFailure({ requestId, error: getGenericError(e.status) })),
      );
  };
}

export function fetchPrevReviews(): AppThunk {
  return (dispatch, getState, { wixReviewsApi }) => {
    const state = getState().reviews;
    if (state?.type !== 'READY') {
      return;
    }
    const cursor =
      state.reviewList.type === 'READY' ? state.reviewList.chunk.previousCursor : undefined;
    assertDefined(cursor);
    const { pagination } = state.config;

    const requestId = uniqueId();
    dispatch(fetchReviewsPageRequest({ requestId }));

    return wixReviewsApi
      .fetchReviewsChunk({
        ...state.config,
        pagination,
        cursor,
      })
      .then((response) =>
        dispatch(fetchReviewsPageSuccess({ response, requestId, action: 'prev' })),
      )
      .catch((e) =>
        dispatch(fetchReviewsPageFailure({ requestId, error: getGenericError(e.status) })),
      );
  };
}

export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST' as const;
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS' as const;
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE' as const;

export const fetchReviewsRequest = (payload: {
  requestId: string;
  pagination: PaginationConfig;
}) => ({
  type: FETCH_REVIEWS_REQUEST,
  payload,
});

export const fetchReviewsSuccess = (payload: {
  response: FetchReviewsResponse;
  requestId: string;
}) => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload,
});

export const fetchReviewsFailure = (payload: { requestId: string; error: ErrorLoud }) => ({
  type: FETCH_REVIEWS_FAILURE,
  payload,
});

// to fetch only reviews without meta information like summary
export const fetchReviews = ({ pagination }: { pagination: PaginationConfig }): AppThunk => {
  return (dispatch, getState, { wixReviewsApi }) => {
    const requestId = uniqueId();
    dispatch(fetchReviewsRequest({ pagination, requestId }));

    // Optimize for filter values which have zero reviews
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    if (pagination.ratingFilter) {
      const count = reviewsState.ratingsBreakdown[pagination.ratingFilter];
      if (count === 0) {
        dispatch(
          fetchReviewsSuccess({
            response: { reviews: [], metadata: { cursors: {} } },
            requestId,
          }),
        );
        return;
      }
    }

    return wixReviewsApi
      .fetchReviews({
        ...reviewsState.config,
        pagination,
      })
      .then((response) => dispatch(fetchReviewsSuccess({ response, requestId })))
      .catch((e) => dispatch(fetchReviewsFailure({ requestId, error: getGenericError(e.status) })));
  };
};

export const REFETCH_REVIEWS_REQUEST = 'REFETCH_REVIEWS_REQUEST' as const;
export const REFETCH_REVIEWS_SUCCESS = 'REFETCH_REVIEWS_SUCCESS' as const;
export const REFETCH_REVIEWS_FAILURE = 'REFETCH_REVIEWS_FAILURE' as const;

export const refetchReviewsRequest = (payload: { requestId: string }) => ({
  type: REFETCH_REVIEWS_REQUEST,
  payload,
});

export const refetchReviewsSuccess = (payload: {
  response: FetchReviewsResponseFull;
  requestId: string;
}) => ({
  type: REFETCH_REVIEWS_SUCCESS,
  payload,
});

export const refetchReviewsFailure = (payload: { requestId: string; error: ErrorLoud }) => ({
  type: REFETCH_REVIEWS_FAILURE,
  payload,
});

export function refetchReviewStates(): AppThunk {
  return (dispatch, getState, { wixReviewsApi }) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    const requestId = uniqueId();
    dispatch(refetchReviewsRequest({ requestId }));
    return wixReviewsApi
      .fetchReviewsInitial({
        ...reviewsState.config,
        pagination: reviewsState.config.pagination,
      })
      .then((response) => dispatch(refetchReviewsSuccess({ response, requestId })))
      .catch((e) =>
        dispatch(refetchReviewsFailure({ requestId, error: getGenericError(e.status) })),
      );
  };
}

export const showAllReviews = (): AppThunk => {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    dispatch(
      fetchReviews({
        pagination: {
          ...reviewsState.config.pagination,
          ratingFilter: undefined,
        },
      }),
    );
  };
};

export const changeOrdering = ({ ordering }: { ordering: Ordering }): AppThunk => {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    const pagination = {
      ...reviewsState.config.pagination,
      ...orderingAndFilterMachine({
        ratingFilter: reviewsState.config.pagination.ratingFilter,
        ordering: reviewsState.config.pagination.ordering,
      })
        .setOrdering(ordering)
        .getState(),
    };
    dispatch(fetchReviews({ pagination }));
  };
};

export const changePageSize = ({ pageSize }: { pageSize: number }): AppThunk => {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    const pagination = {
      ...reviewsState.config.pagination,
      pageSize,
    };
    dispatch(fetchReviews({ pagination }));
  };
};

export const changeRatingFilter = ({
  ratingFilter,
}: {
  ratingFilter: number | undefined;
}): AppThunk => {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    const pagination = {
      ...reviewsState.config.pagination,
      ...orderingAndFilterMachine({
        ratingFilter: reviewsState.config.pagination.ratingFilter,
        ordering: reviewsState.config.pagination.ordering,
      })
        .setFilter(ratingFilter)
        .getState(),
    };
    dispatch(fetchReviews({ pagination }));
  };
};

export const changeOrderingAndFilter = ({
  ordering,
  ratingFilter,
}: {
  ordering: Ordering;
  ratingFilter: number | undefined;
}): AppThunk => {
  return (dispatch, getState) => {
    const reviewsState = getState().reviews;
    if (reviewsState.type !== 'READY') {
      return;
    }
    const pagination = {
      ...reviewsState.config.pagination,
      ...orderingAndFilterMachine({
        ratingFilter: reviewsState.config.pagination.ratingFilter,
        ordering: reviewsState.config.pagination.ordering,
      })
        .setFilterAndOrdering(ratingFilter, ordering)
        .getState(),
    };
    dispatch(fetchReviews({ pagination }));
  };
};

export const UNMOUNT_RESOURCE = 'UNMOUNT_RESOURCE' as const;
export const unmountResource = (payload: {}) => ({
  type: UNMOUNT_RESOURCE,
  payload,
});

export type UnmountResourceAction = ReturnType<typeof unmountResource>;

export const FOCUS_ROOT = 'FOCUS_ROOT' as const;
export const focusRoot = (payload?: {}) => ({
  type: FOCUS_ROOT,
  payload,
});

export type FocusRootAction = ReturnType<typeof focusRoot>;

export type FetchReviewsInitialActions =
  | ReturnType<typeof fetchReviewsInitialSuccess>
  | ReturnType<typeof fetchReviewsInitialRequest>
  | ReturnType<typeof fetchReviewsInitialFailure>;

export const isReviewsFetchInitialAction: (
  action: AllActions,
) => action is FetchReviewsInitialActions = isOfType([
  FETCH_REVIEWS_INITIAL_SUCCESS,
  FETCH_REVIEWS_INITIAL_REQUEST,
  FETCH_REVIEWS_INITIAL_FAILURE,
]);

export type FetchDeeplinkActions =
  | ReturnType<typeof fetchDeepLinkSuccess>
  | ReturnType<typeof fetchDeepLinkRequest>
  | ReturnType<typeof fetchDeepLinkFailure>;

export const isFetchDeeplinkAction: (action: AllActions) => action is FetchDeeplinkActions =
  isOfType([FETCH_DEEP_LINK_SUCCESS, FETCH_DEEP_LINK_REQUEST, FETCH_DEEP_LINK_FAILURE]);

export type ReviewsPagingActions =
  | ReturnType<typeof fetchReviewsPageSuccess>
  | ReturnType<typeof fetchReviewsPageRequest>
  | ReturnType<typeof fetchReviewsPageFailure>
  | ReturnType<typeof fetchReviewsSuccess>
  | ReturnType<typeof fetchReviewsRequest>
  | ReturnType<typeof fetchReviewsFailure>;

export const isReviewsPagingAction: (action: AllActions) => action is ReviewsPagingActions =
  isOfType([
    FETCH_REVIEWS_PAGE_REQUEST,
    FETCH_REVIEWS_PAGE_SUCCESS,
    FETCH_REVIEWS_PAGE_FAILURE,
    FETCH_REVIEWS_REQUEST,
    FETCH_REVIEWS_SUCCESS,
    FETCH_REVIEWS_FAILURE,
  ]);

export type RefetchReviewsActions =
  | ReturnType<typeof refetchReviewsSuccess>
  | ReturnType<typeof refetchReviewsRequest>
  | ReturnType<typeof refetchReviewsFailure>;

export const isReviewsRefetchAction: (action: AllActions) => action is RefetchReviewsActions =
  isOfType([REFETCH_REVIEWS_SUCCESS, REFETCH_REVIEWS_REQUEST, REFETCH_REVIEWS_FAILURE]);
