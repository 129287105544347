import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {CloseSmall} from '@wix/wix-ui-icons-common/on-stage'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {IconButton} from 'wix-ui-tpa/cssVars'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import {shouldShowCountdown} from '../../../../../selectors/layout'
import classesV2 from '../../../../classes-v2.scss'
import {Countdown} from '../../../../countdown'
import {AdditionalInfoFullDateLocation} from '../../../../full-date-location'
import {LinkToPage} from '../../../../link-to-page'
import {Members} from '../../../../members'
import {Ribbon} from '../../../../ribbon'
import {RsvpButton} from '../../../../rsvp-button'
import {SocialBar} from '../../../../social-bar'
import {OverlayProps} from './interfaces'
import s from './overlay.scss'
import {classes} from './overlay.st.css'

export const Overlay = ({event, toggleOverlay, hasRibbon, showMembers, membersEnabled}: OverlayProps) => {
  const {t} = useTranslation()
  const {
    allBreakpoints: {isListFullDateOrLocationEnabled, isListExtraInfoDateOrLocationVisible_V2, isListCountdownEnabled},
  } = useVisibilityStyles()
  const {experiments} = useExperiments()
  const listDateLocationFormatSettingEnabled = experiments.enabled(ExperimentNames.ListDateLocationFormatSetting)

  const dateLocationVisible = listDateLocationFormatSettingEnabled
    ? isListExtraInfoDateOrLocationVisible_V2()
    : isListFullDateOrLocationEnabled

  useEffect(() => {
    focusElement({
      selector: hookToAttributeSelector(DH.expandedCardEventName(event.id)),
      preventScroll: true,
      canFocus: true,
    })
  }, [])

  return (
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.closeWrapper}>
          <IconButton
            onClick={toggleOverlay}
            className={classes.close}
            icon={<CloseSmall />}
            aria-label={t('a11y.close')}
          />
        </div>
        <div className={classNames(s.eventInfo, {[s.updatedVisibilityVars]: listDateLocationFormatSettingEnabled})}>
          {hasRibbon && <Ribbon event={event} className={s.ribbon} />}
          {isListCountdownEnabled() && shouldShowCountdown(event) && (
            <div className={s.countdown}>
              <Countdown event={event} />
            </div>
          )}
          <div className={s.title}>
            <LinkToPage allowExternal event={event} dataHook={DH.expandedCardEventName(event.id)}>
              {event.title}
            </LinkToPage>
          </div>
          {dateLocationVisible && (
            <div className={s.additionalDateLocation}>
              <AdditionalInfoFullDateLocation
                event={event}
                dateClassName={classesV2.hoverEventDate}
                locationClassName={classesV2.hoverEventLocation}
              />
            </div>
          )}
          {!!event.description && <div className={s.description}>{event.description}</div>}
          {showMembers && (
            <div className={s.members}>
              <Members event={event} />
            </div>
          )}
          {membersEnabled && <SocialBar event={event} t={t} className={s.socialBar} />}
        </div>
      </div>
      <RsvpButton event={event} containerClassName={s.button} />
    </div>
  )
}
