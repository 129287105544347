import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../../commons/selectors/environment'
import {getComponentConfig, isResponsive} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Cards as CardsPresentation} from './cards'
import {CardsOwnProps, CardsStateProps} from './interfaces'

const mapRuntimeToProps = ({state}: AppProps): CardsStateProps => {
  const responsive = isResponsive(getComponentConfig(state))

  return {
    editor: isEditor(state),
    responsive,
  }
}

export default connect<CardsOwnProps, CardsStateProps>(mapRuntimeToProps)(withTranslation()(CardsPresentation))
